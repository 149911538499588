import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import useEshop from "../../store/eshopStore";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "O nás",
    menus: [
      /* { href: "#", label: "Predstavenie firmy" },
      { href: "#", label: "Náš tím" },
      { href: "#", label: "Kariéra" },*/
      { href: "kontakt", label: "Kontakt" },
    ],
  },
  {
    id: "1",
    title: "Obchodné podmieky",
    menus: [
      { href: "vseobecne-obchodne-podmienky", label: "Obchodné podmienky" },
      { href: "vratenie-tovaru", label: "Vrátenie tovaru" },
      /* { href: "#", label: "Reklamačné podmienky" },
      { href: "#", label: "Doprava" },
      { href: "#", label: "Poistenie" }, */
    ],
  },
  {
    id: "2",
    title: "Nájdete nás",
    menus: [
      /* { href: "#", label: "Facebook" },
      { href: "#", label: "Instagram" },
      { href: "#", label: "YouTube" }, */

    ],
  },
  {
    id: "4",
    title: "Kontakt",
    menus: [
      { href: "tel:00421918170768", label: "+421 918 170 768" },
      { href: "mailto:info@mojavec.com", label: "info@mojavec.com" },
      { href: "kontakt", label: "Otv: Po-Pia: 9.00-18.00 hod" },

    ],
  },
];

const Footer: React.FC = () => {
  const eshop = useEshop((state) => state.settings);
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}

                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1 ">
            <Logo/>
            <div style={{color:"gray",fontSize:"0.8em"}} title={"deployed at "+eshop.deployDate}>{eshop.version}</div>
          </div>

          <div className="col-span-2 flex items-center md:col-span-3">
            BJK Foto s.r.o.<br />
            Svätotrojičné nám. 19,<br />
            963 01 Krupina<br />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
