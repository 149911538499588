import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import React, {SetStateAction, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";

import useCartStore from "../../store/cartStore";
import useEshop from "../../store/eshopStore";
import useBoolean from "react-use/lib/useBoolean";
import Checkbox from "../../shared/Checkbox/Checkbox";
import {Simulate} from "react-dom/test-utils";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import ButtonThird from "../../shared/Button/ButtonThird";
import axios from "axios";
import cartStore from "../../store/cartStore";
import eshopStore from "../../store/eshopStore";


function formatPrice(price: number) {
  // Convert the price to a string with fixed two decimal places
  let priceStr = price.toFixed(2);

  // Split the string into the whole number and decimal parts
  let parts = priceStr.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Add spaces for thousands
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Combine the parts with a comma as the decimal delimiter
  return formattedIntegerPart + ',' + decimalPart;
}
const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const CheckoutPage = () => {
  // const cart = useCartStore((state) => state.cart);
  const cart = useCartStore();
  const isDeliveryFree = useCartStore((state) => state.isDeliveryFree);
  const customer = useCartStore((state) => state.customerData);
  const chosenDelivery = useCartStore((state) => state.chosenDelivery);
  const chosenPayment = useCartStore((state) => state.chosenPayment);
  const eshop = useEshop((state) => state.settings);
  const cartTotalPrice = useCartStore((state) => state.totalPrice);
  const addItem = useCartStore((state) => state.addItem);
  const removeItem = useCartStore((state) => state.removeItem);
  const clearCart = useCartStore((state) => state.clearCart);
  const setItemCount = useCartStore((state) => state.setItemCount);
  const setDeliveryFree  = useCartStore((state) => state.setDeliveryFree );
  const [tabActive, setTabActive] = useState<
      "ContactInfo"  | "PaymentMethod"
  >("ContactInfo");
  const [contactData, setContactData] = useState({});
  const [orderStatus, setOrderStatus] = useState(1); // 1 in prepare process, // 2 succ sent // -1 sent failed // -3 sending

  const updateCart = useCartStore(state => state.updateCart)
  const [isAcceptedCondition,setIsAcceptedCondition]=useState(false)
  const [isEmailValidated,setIsEmailValidated]=useState(false)
  // const [showCheckoutError,setShowCheckoutError]=useState(true)



  const toggleAcceptation = () => {
    const new_value=!isAcceptedCondition;
    setIsAcceptedCondition(new_value)
  };


  useEffect(() => {
    cart.updateFees(eshop.deliveryOptions[chosenDelivery].cost,eshop.paymentOptions[chosenPayment].cost)
  }, [chosenDelivery,chosenPayment]);


  useEffect(() => {
    if(cartTotalPrice<eshop.deliveryFreeFrom)setDeliveryFree (false); else setDeliveryFree (true)
  }, [cartTotalPrice, eshop.deliveryFreeFrom]);

  useEffect(() => {
    setIsEmailValidated(validateEmail(customer.email))
  }, [customer.email]);


  const handleContactInfoChange = (data: SetStateAction<{}>) => {
    setContactData(data);
    // console.log('contac data changed:',data)
  };

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  const handleCountValueChange = (id: number, size: string | undefined,color:string| undefined,cut:string| undefined, value: number) => {
    setItemCount(id,size,color,cut,value)
    /* setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

     */


  };
  function getCutType(selectedCut: any) {
    switch(selectedCut) {
      case "damsky_strih":
        return "dámsky";
      case "pansky_strih":
        return "pánsky";
      case "unisex_strih":
        return "unisex";
      default:
        return "";
    }
  }
  const renderProduct = (item: Product, index: number) => {
    const { name, price, image, id,count, color, cut, size,selectedCut } = item;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
              src={"https://mojavec.com/web_files/t_"+image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to="/product-detail" className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to="/product-detail">{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5  text-sm text-slate-600 dark:text-slate-300">


                  {size?<div >
                    Veľkosť:&nbsp;
                    <span>{size}</span>
                  </div>:''}

                  {selectedCut?<div >
                    Strih:&nbsp;
                    <span>{getCutType(selectedCut)}</span>
                  </div>:''}


                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber className="relative z-10" defaultValue={item.count}
                             min={1}
                             max={99}
                             onChange={(value) => handleCountValueChange(item.id, item.size,item.color,item.selectedCut, value)}/>
            </div>

            <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
                onClick={() => removeItem(id, size, color, selectedCut)}
            >
              Odstrániť
            </button>
          </div>
        </div>
      </div>
    );
  };

  const sendOrder = () => {
    setOrderStatus(3)  //sending
    // console.log(cart)
    //cart.action='new_order';
    // const formData = new FormData();

    // formData.append('eshopCart', cart);
    // formData.append('action', 'new_order');
    //const dataToSend = {cart:cart,action:'new_order'}
    const formData = new FormData();
    formData.append('eshopData', JSON.stringify(cart))
    formData.append('action', 'new_order')
    const jwt=null;
    return axios.post('https://api.mojavec.com/v1/post/index.php', formData, {
      headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`},
    })
        .then(responsef => {
          if(responsef.data.data.status===1) {
             console.log('succ')
            // cartStore.em
            /* localStorage.removeItem('cart');
            */
            cart.clearCustomerData()
            cart.clearCart()
            cart.clearChosenDelivery()
            cart.clearChosenPayment()
            localStorage.removeItem('cart');
            setOrderStatus(2);
          }else{
            // console.log('fails')
            setOrderStatus(-1);
            // setShowCheckoutError(true)
            setIsAcceptedCondition(false)
          }
        })
        .catch(error => {
          // handle error here
          setIsAcceptedCondition(false)
          setOrderStatus(-1);
          // setShowCheckoutError(true)


          console.error(error);
        });

    /*
    const postData = {
      contactData: contactData,
      eshopCart: cart,
      action: 'new_order'
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    };

    fetch('https://mojavec.com/api/v1/post', requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('Data sent successfully:', data);
          // Handle success, update state, etc.
        })
        .catch(error => {
          console.error('Error sending data:', error);
          // Handle errors
        });

     */
  };
  useEffect(()=> {
    console.log('orderStatus changed:',orderStatus)

  },[orderStatus])

  const renderLeft = () => {
    return (
        <div className="space-y-8">
          <div id="ContactInfo" className="scroll-mt-24">
            <ContactInfo
              // isActive={tabActive === "ContactInfo"}
              isActive
              /* onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
                onCloseActive={() => {
                  setTabActive("PaymentMethod");
                  handleScrollToEl("PaymentMethod");
                }}

               */
           onContactInfoChange={handleContactInfoChange}/>
        </div>


        <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
              isActive
              /*
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}

               */
          />
        </div>
      </div>
    );
  };



  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Odoslanie objednávky || Moja Vec</title>
      </Helmet>
      {(orderStatus===1)&& //in prepare process
          <>
      {cart?.cart?.length?
      <main className="container py-16 lg:pb-28 lg:pt-20 bg-white p-5 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Zhrnutie a odoslanie objednávky
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Úvodná strana
            </Link>

            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Potvrdenie objednávky</span>
          </div>
        </div>
        {/* {(eshop.deliveryOptions[chosenDelivery].cost + eshop.paymentOptions[chosenPayment].cost + cartTotalPrice)<eshop.deliveryFreeFrom&& */ }
        {!isDeliveryFree&&
        <div className="text-white w-full p-2 text-center " style={{borderRadius:"10px",backgroundColor:"#5a9d5f",marginBottom:"10px"}}>
          {/* Nakúpte ešte za <strong>{formatPrice(eshop.deliveryFreeFrom-(eshop.deliveryOptions[chosenDelivery].cost + eshop.paymentOptions[chosenPayment].cost + cartTotalPrice))}</strong> €  a dopravu máte zadarmo ! */}
          Nakúpte ešte za <strong>{formatPrice(eshop.deliveryFreeFrom-cartTotalPrice)}</strong> €  a dopravu máte zadarmo !
        </div>
        }
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold">Zhrnutie objednávky</h3>
            <div className="border border-slate-200 dark:border-slate-700 rounded-xl " style={{backgroundColor: "#fafafa", padding: "15px", marginTop: "5px"}}>
              {customer.company && customer.isCompanyOrder && <>
                <strong>{customer.company} </strong><br/>
                {customer.cin && <><strong>IČO:</strong> {customer.cin}<br/></>}
                {customer.tin && <><strong>DIČ:</strong> {customer.tin}<br/></>}
                {customer.vat && <><strong>IČ DPH:</strong> {customer.vat}<br/></>}

              </>
              }
              <strong>{customer.name} {customer.surname}</strong><br/>
              {customer.street}{customer.street_number && <>, {customer.street_number}</>}<br/>
              {customer.city}{customer.postal_code && <>, {customer.postal_code}</>}<br/>
              {customer.state}<br/>
              {customer.tel && <><strong>Tel:</strong> {customer.tel}<br/></>}
              {customer.email && <><strong>E-mail:</strong> {customer.email}<br/></>}

              {customer.deliverToAnotherAddress && <div>
                <br/>
                <strong>Doručiť na adresu:</strong><br/>
                {customer.name2} {customer.surname2}<br/>
                {customer.street2}{customer.street_number2 && <>, {customer.street_number2}</>}<br/>
                {customer.city2}{customer.postal_code2 && <>, {customer.postal_code2}</>}<br/>
                {customer.state2}<br/>
                {customer.tel2 && <><strong>Tel:</strong> {customer.tel2}<br/></>}
                {customer.email2 && <><strong>E-mail:</strong> {customer.email2}<br/></>}
              </div>
              }

            </div>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
              {cart.cart.map(renderProduct)}
            </div>

            <div className="mt-10 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 " style={{backgroundColor: "#fafafa", padding: "10px", borderRadius: "10px"}}>


              <div className="mt-4 flex justify-between py-2.5">
                <span>Cena za tovar</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {formatPrice(cartTotalPrice)} €
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Doprava</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {eshop.deliveryOptions[chosenDelivery].name}
                  {
                    isDeliveryFree ? <>(zdarma)</> : <>
                      ({formatPrice(eshop.deliveryOptions[chosenDelivery].cost)} €)
                    </>
                  }
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Platba</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {eshop.paymentOptions[chosenPayment].name}
                  {isDeliveryFree ? '' : <>
                    ({formatPrice(eshop.paymentOptions[chosenPayment].cost)} €)
                  </>}
                </span>
              </div>
              {/* <div className="flex justify-between py-2.5">
                <span>Tax estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $24.90
                </span>
              </div> */}
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4" style={{fontSize: "1.5em"}}>
                <span>Celkom k úhrade</span>
                <span>
                  {isDeliveryFree ? <>{formatPrice(cartTotalPrice)} </> : <>
                    {formatPrice(eshop.deliveryOptions[chosenDelivery].cost + eshop.paymentOptions[chosenPayment].cost + cartTotalPrice)}
                  </>}
                  €</span>
              </div>
            </div>

            <div style={{marginTop:"15px"}}>
              <Checkbox
                  className="!text-sm"
                  name="conditionAcceptation"
                  label="Súhlasím s obchodnými podmienkami a podmienkami ochrany osobných údajov a chcem prejsť k objednaniu tovaru."
                  defaultChecked={isAcceptedCondition}
                  // @ts-ignore
                  onChange={toggleAcceptation}
              />
            </div>

            {isAcceptedCondition?(
                isEmailValidated?
            <ButtonPrimary onClick={sendOrder} className="mt-8 w-full">
              Odoslať objednávku s povinnosťou platby
            </ButtonPrimary>:<ButtonSecondary className="mt-8 w-full"  disabled>
                  Email nemá správny formát
            </ButtonSecondary>):
                <ButtonSecondary className="mt-8 w-full"  disabled>
                  Zakliknite súhlas s obchodnými podmienkami
            </ButtonSecondary>
            }

          </div>
        </div>
      </main>
          :
          <main className="container py-16 lg:pb-28 lg:pt-20 ">
            <div className="mb-16">
              <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                Nákupný košík je prázdny
              </h2>
            </div>
          </main>
      }
          </>
      }

      {

          (orderStatus === -1 ) &&
          <main className="container py-16 lg:pb-28 lg:pt-20 ">
            <div className="mb-16">
              <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold text-center">
                Chyba pri odosielaní objednávky :/
              </h2>
          <div className="text-white w-full p-2 text-center " style={{borderRadius: "10px", backgroundColor: "#a21c26", marginBottom: "10px", marginTop: "10px"}}>
            Je nám ľúto, vytvorenie objednávky nebolo úspešné. V prípade záujmu o produkty nás kontaktujte telefonicky na <a href="tel:00421905657019">0905 657 019</a><br/>
            <ButtonSecondary className="mt-8 w-full" onClick={()=>{setOrderStatus(1);}} >
              OK
            </ButtonSecondary>
          </div>
            </div>
          </main>
      }

      {(orderStatus === 2) &&
          <main className="container py-16 lg:pb-28 lg:pt-20 ">
            <div className="mb-16">
              <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold text-center">
                Objednávka bola odoslaná !
              </h2>
              <div className="text-white w-full p-4 text-center " style={{borderRadius: "10px", backgroundColor: "#28622b", marginBottom: "10px", marginTop: "20px", fontSize:'1.2em'}}>
                Vašu objednávku sme úspešne prijali. O jej ďalšom spracovaní Vás budeme informovať emailom.
                Ďakujeme !

              </div>
            </div>
          </main>
      }
      {(orderStatus === 3) &&
          <main className="container py-16 lg:pb-28 lg:pt-20 ">
            <div className="mb-16">
              <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold text-center">
                Prosíme, čakajte
              </h2>
              <div className=" w-full p-4 text-center " style={{borderRadius: "10px", backgroundColor: "#96ab99", marginBottom: "10px", marginTop: "20px", fontSize:'1.2em'}}>
                Vašu objednávku práve odosielame...

              </div>
            </div>
          </main>
      }
    </div>
  );
};

export default CheckoutPage;
