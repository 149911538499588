import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import {LocationStates} from "../routers/types";

export const MEGAMENU_TEMPLATES: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Page",
    args: "",
    children: [
      { id: ncNanoId(), href: "/", name: "Home  1",args: "" },
      { id: ncNanoId(), href: "/home2", name: "Home  2", isNew: true,args: "" },
      { id: ncNanoId(), href: "/", name: "Header  1",args: "" },
      { id: ncNanoId(), href: "/home2", name: "Header  2", isNew: true,args: "" },
      { id: ncNanoId(), href: "/", name: "Coming Soon",args: "" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Shop Pages",
    args: "",
    children: [
      { id: ncNanoId(), href: "/page-collection", name: "Category Page 1",args: "" },
      { id: ncNanoId(), href: "/page-collection-2", name: "Category Page 2",args: "" },

      { id: ncNanoId(), href: "/product-detail-2", name: "Product Page 2" ,args: ""},
      { id: ncNanoId(), href: "/cart", name: "Cart Page",args: "" },
      { id: ncNanoId(), href: "/checkout", name: "Checkout Page",args: "" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Other Pages",
    args: "",
    children: [
      { id: ncNanoId(), href: "/checkout", name: "Checkout Page" ,args: ""},
      { id: ncNanoId(), href: "/page-search", name: "Search Page",args: "" },
      { id: ncNanoId(), href: "/cart", name: "Cart Page",args: "" },
      { id: ncNanoId(), href: "/account", name: "Accout Page",args: "" },
      { id: ncNanoId(), href: "/account-my-order", name: "Order Page" ,args: ""},
      { id: ncNanoId(), href: "/subscription", name: "Subscription",args: "" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Blog Page",
    args: "",
    children: [
      { id: ncNanoId(), href: "/blog", name: "Blog Page",args: "" },
      { id: ncNanoId(), href: "/blog-single", name: "Blog Single",args: "" },
      { id: ncNanoId(), href: "/about", name: "About Page",args: "" },
      { id: ncNanoId(), href: "/contact", name: "Contact Page" ,args: ""},
      { id: ncNanoId(), href: "/login", name: "Login",args: "" },
      { id: ncNanoId(), href: "/signup", name: "Signup" ,args: ""},
    ],
  },
];

const OTHER_PAGE_CHILD: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Demo 1",
  args: ""
  },
  {
    id: ncNanoId(),
    href: "/home2",
    name: "Home Demo 2",
    args: ""
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Category Pages",
    type: "dropdown",
    args: "",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Category page 1",args: ""
      },
      {
        id: ncNanoId(),
        href: "/page-collection-2",
        name: "Category page 2",
        args: ""
      },
    ],
  },

  {
    id: ncNanoId(),
    href: "/cart",
    name: "Cart Page",
    args: ""
  },
  {
    id: ncNanoId(),
    href: "/checkout",
    name: "Checkout Page",
    args: ""
  },
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Search Page",args: ""
  },
  {
    id: ncNanoId(),
    href: "/account",
    name: "Account Page",
    args: ""
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Other Pages",
    type: "dropdown",
    args: "",
    children: [
      {
        id: ncNanoId(),
        href: "/about",
        name: "About",
        args: "",
      },
      {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
        args: "",
      },
      {
        id: ncNanoId(),
        href: "/login",
        name: "Login",
        args: "",
      },
      {
        id: ncNanoId(),
        href: "/signup",
        name: "Signup",
        args: "",
      },
      {
        id: ncNanoId(),
        href: "/subscription",
        name: "Subscription",
        args: "",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
    type: "dropdown",
    args: "",
    children: [
      {
        id: ncNanoId(),
        href: "/blog",
        name: "Blog Page",
        args: "",
      },
      {
        id: ncNanoId(),
        href: "/blog-single",
        name: "Blog Single",
        args: "",
      },
    ],
  },
];


export const NAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/produkty/all"  as keyof LocationStates,
    name: "Všetko",
    args: "",
  }, {
    id: ncNanoId(),
    href: "/produkty/hrnceky"  as keyof LocationStates,
    name: "Hrnčeky",
    args: "",
  },
  {
    id: ncNanoId(),
    //href: "/kategoria/tricka",
    // href: "/kategoria",
     //href: "/produkty/:slug",

    href: "/produkty/tricka"  as keyof LocationStates,
    // args: "?cat=154&pcat=165",
    args: "",
    // name: "Tričká dievcata",
    name: "Tričká",
  },
  {
    id: ncNanoId(),
    // @ts-ignore
    href: "/produkty/mikiny/"  as keyof LocationStates,
    name: "Mikiny",
    args: "",
  },{
    id: ncNanoId(),
    // @ts-ignore
    href: "/produkty/siltovky/"  as keyof LocationStates,
    name: "Šiltovky",
    args: "",
  },{
    id: ncNanoId(),
    // @ts-ignore
    href: "/produkty/hrnceky/"  as keyof LocationStates,
    name: "Hrnčeky",
    args: "",
  },
  {
    id: ncNanoId(),
    // @ts-ignore
    href: "/produkty/tasky/"  as keyof LocationStates,
    name: "Tašky",
    args: "",
  },

  {
    id: ncNanoId(),
    href: "/produkty/bjk-fotoboxy"  as keyof LocationStates,
    name: "Fotoboxy",
    args: "",
  },
    /*
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Rozšírená ponuka",
    type: "megaMenu",
    children: MEGAMENU_TEMPLATES,
    args: "",
  }

     */
];
