import React from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import NcImage from "../../shared/NcImage/NcImage";
import {nftsAbstracts} from "../../contains/fakeData";
import {useNavigate} from "react-router-dom";
import ProductsSearchList from "../ProductsSearchList";

function PageHome() {
    const navigate = useNavigate();
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      <SectionHero2 />

        {/*
      <div className="mt-24 lg:mt-32">
        <DiscoverMoreSlider

        />
      </div>
      */}

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}
        <SectionSliderProductCard
            heading={"Najnovšie pridané produkty"}
            data={[
            PRODUCTS[4],
            SPORT_PRODUCTS[5],
            PRODUCTS[7],
            SPORT_PRODUCTS[1],
            PRODUCTS[6],
          ]}
        />


        {/* SECTION start exploring
        <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div>


        <SectionGridFeatureItems />
          */}
        {/*
        <SectionPromo2 />
 */}



          <NcImage
              containerClassName="flex h-full w-full flex-shrink-0 rounded-3xl overflow-hidden"
              src="https://mojavec.com//web_files/eDZrNdA40wxYL6E4EylP32a5WEVjmB.jpg"
              onClick={()=>navigate("/produkty/bjk-fotoboxy")}
              style={{cursor:"pointer"}}
              />

        {/*
        <SectionSliderCategories
            heading="Mohlo by Vás zaujímať"
        />
 */}
        {/* SECTION */}
          {/* <SectionPromo3 /> */}
          {/*
        <SectionSliderProductCard
          heading="TOP produkty pre Vás"
          subHeading="Výber toho najlepšieho"
        />


        <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <div>
            <Heading rightDescText="From the Ciseco blog">
              The latest news
            </Heading>
            <SectionMagazine5 />
            <div className="flex mt-16 justify-center">
              <ButtonSecondary>Show all blog articles</ButtonSecondary>
            </div>
          </div>
        </div>
*/}
        {/*
        <SectionClientSay />
        */}
      </div>
    </div>
  );
}

export default PageHome;
