import Label from "components/Label/Label";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "../../shared/Select/Select";
import useCartStore from "../../store/cartStore";
import useEshop from "../../store/eshopStore";

interface Props {
  isActive: boolean;
  /* onCloseActive: () => void;
  onOpenActive: () => void;

   */
}

function formatPrice(price: number) {
  // Convert the price to a string with fixed two decimal places
  let priceStr = price.toFixed(2);

  // Split the string into the whole number and decimal parts
  let parts = priceStr.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Add spaces for thousands
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Combine the parts with a comma as the decimal delimiter
  return formattedIntegerPart + ',' + decimalPart;
}

const PaymentMethod: FC<Props> = ({
  isActive,
  /* onCloseActive,
  onOpenActive,*/
}) => {
  const [mothodActive, setMethodActive] = useState<
    "Credit-Card" | "Internet-banking" | "Wallet"
  >("Credit-Card");

  const cart = useCartStore();
  const chosenDelivery = useCartStore((state) => state.chosenDelivery);
  const eshop = useEshop((state) => state.settings);

  const handleSelectChangeDelivery = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    cart.setChosenDelivery(value)
    console.log("chosen delivery value",value)
    console.log("chosen delivery ",chosenDelivery)
    console.log(typeof chosenDelivery)
  };

  const handleSelectChangePayment = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    cart.setChosenPayment(value)

  };


  const renderPaymentMethod = () => {

    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl " style={{backgroundColor:"#fafafa"}}>
        <div className="p-6 flex flex-col sm:flex-row items-start" style={{backgroundColor:"#eaeaea"}}>
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.92969 15.8792L15.8797 3.9292"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.1013 18.2791L12.3013 17.0791"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.793 15.5887L16.183 13.1987"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 21.9985H22"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="sm:ml-8" >
            <h3 className=" text-slate-700 dark:text-slate-400 flex ">
              <span className="uppercase tracking-tight font-bold ">Doprava a platba </span>

            </h3>

          </div>

        </div>

        <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-6 ${
                isActive ? "block" : "hidden"
            }`}
        >
          {cart.isDeliveryFree && <span className="text-red-500">Dopravu máte zadarmo</span>}
          {/* ==================== */}
          <div style={{marginBottom: "10px"}}>
            <Label>Vyberte dopravu</Label>
            <Select className="mt-1.5" name="strih" onChange={handleSelectChangeDelivery}>
              {    // @ts-ignore
                eshop?.deliveryOptions.map((option) => (
                  // @ts-ignore
                  <option key={option.id} value={option.id}>
                    {option.name} {!cart.isDeliveryFree &&<>({formatPrice(option.cost)} €)</>}
                  </option>
              ))}
            </Select>
          </div>

          <div style={{marginBottom: "10px"}}>
            <Label>Vyberte spôsob platby</Label>
            <Select className="mt-1.5" name="strih"  onChange={handleSelectChangePayment} >
              {


                    // @ts-ignore
                    //.filter(option => option.visible_in_delivery.includes(chosenDelivery)) // Filter options based on chosenDelivery
                    // @ts-ignore
                eshop?.paymentOptions.filter(option => option.visible_in_delivery.includes(Number(chosenDelivery ?? 0))).map((option) => (



                        // @ts-ignore
                        <option key={option.id} value={option.id}>
                          {option.name} {!cart.isDeliveryFree && <>({formatPrice(option.cost)} €)</>}
                        </option>


                    ))

              }
            </Select>
          </div>

          {/*
          <div>{renderDebitCredit()}</div>
          <div>{renderInterNetBanking()}</div>
          <div>{renderWallet()}</div>
          */}

          {/*
          <div className="flex pt-6">
            <ButtonPrimary
                className="w-full max-w-[240px]"
                onClick={onCloseActive}
            >
              Confirm order
            </ButtonPrimary>
            <ButtonSecondary className="ml-3" onClick={onCloseActive}>
              Cancel
            </ButtonSecondary>
          </div>
          */}
        </div>
      </div>
    );
  };

  return renderPaymentMethod();
};

export default PaymentMethod;
