import Label from "components/Label/Label";
import React, {FC, useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";
import Select from "../../shared/Select/Select";
import {useUserStore} from "../../store/userStore";
import useBoolean from "react-use/lib/useBoolean";
import useCartStore from "../../store/cartStore";

interface Props {
  isActive: boolean;
  /* onOpenActive: () => void;
  onCloseActive: () => void;

   */
  onContactInfoChange: (data: any) => void;
}

const ContactInfo: FC<Props> = ({ isActive, onContactInfoChange }) => {


  const cartStore = useCartStore();
  const customerData = cartStore?.customerData || {};
  /* const [contactInfo, setContactInfo] = useState({
    isCompanyOrder: customerData.isCompanyOrder ?? false,
    company: customerData.company ?? '',
    cin: customerData.cin ?? '',
    tin: customerData.tin ?? '',
    vat: customerData.vat ?? '',
    name: customerData.name ?? '',
    surname: customerData.surname ?? '',
    street: customerData.street ?? '',
    street_number: customerData.street_number ?? '',
    city: customerData.city ?? '',
    postcode: customerData.postcode ?? '',
    state: customerData.state ?? '',
    tel: customerData.tel ?? '',
    email: customerData.email ?? ''
  }); */
  const { user, setUser, emptyUser } = useUserStore();
  /* const [isCompanyOrder, setIsCompanyOrder] = useState(false); */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedContactInfo = {
      ...cartStore.customerData,
      [name]: value,
    };
    cartStore.setCustomerData(updatedContactInfo)
  };
   const toggleOrderType = () => {
     const new_value=!cartStore.customerData.isCompanyOrder;
     const updatedContactInfo = {
       ...cartStore.customerData,
       isCompanyOrder: new_value,
     };
     cartStore.setCustomerData(updatedContactInfo)
  };

   const toggleDeliverToAnotherAddress = () => {
     const new_value=!cartStore.customerData.deliverToAnotherAddress;
     const updatedContactInfo = {
       ...cartStore.customerData,
       deliverToAnotherAddress: new_value,
     };
     cartStore.setCustomerData(updatedContactInfo)
  };

 const toggleAutomaticRegistration = () => {
     const new_value=!cartStore.customerData.automaticRegistration;
     const updatedContactInfo = {
       ...cartStore.customerData,
       automaticRegistration: new_value,
     };
     cartStore.setCustomerData(updatedContactInfo)
  };
 const toggleOrderAsAGift = () => {
     const new_value=!cartStore.customerData.orderAsAGift;
     const updatedContactInfo = {
       ...cartStore.customerData,
       orderAsAGift: new_value,
     };
     cartStore.setCustomerData(updatedContactInfo)
  };


  const renderAccount = () => {


    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0" style={{backgroundColor:"#fafafa"}}>
        <div className="flex flex-col sm:flex-row items-start p-6 " style={{backgroundColor:"#eaeaea"}}>
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase tracking-tight font-bold ">Fakturačné a doručovacie údaje</span>

            </h3>

          </div>

        </div>
        <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
                isActive ? "block" : "hidden"
            }`}
        >
          <div className="flex justify-between flex-wrap items-baseline">
            <h3 className="text-lg font-semibold">Fakturačné údaje</h3>

            {!!user &&
                <span className="block text-sm my-1 md:my-0">
              Máte u nás účet?
              <a href="##" className="text-primary-500 font-medium">
                Prihlásiť sa
              </a>
            </span>
            }

          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Checkbox
                  className="!text-sm"
                  name="isCompanyOrder"
                  label="Objednávka na firmu"
                  defaultChecked={customerData.isCompanyOrder ?? false}
                  onChange={toggleOrderType}
                  // @ts-ignore
                  // onChange={handleChange}
              />
            </div>
          </div>
          {customerData?.isCompanyOrder &&
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">Názov firmy</Label>
                  <Input className="mt-1.5" name="company" defaultValue={customerData.company ?? ''} onChange={handleChange}/>
                </div>
                <div>
                  <Label className="text-sm">IČO</Label>
                  <Input className="mt-1.5" name="cin" defaultValue={customerData.cin ?? ''} onChange={handleChange}/>
                </div>
                <div>
                  <Label className="text-sm">DIČ</Label>
                  <Input className="mt-1.5" name="tin" defaultValue={customerData.tin ?? ''} onChange={handleChange}/>
                </div>
                <div>
                  <Label className="text-sm">IČ DPH</Label>
                  <Input className="mt-1.5" name="vat" defaultValue={customerData.vat ?? ''} onChange={handleChange}/>
                </div>
              </div>}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Meno</Label>
              <Input className="mt-1.5" name="name" defaultValue={customerData.name ?? ''} onChange={handleChange}/>
            </div>
            <div>
              <Label className="text-sm">Priezvisko</Label>
              <Input className="mt-1.5" name="surname" defaultValue={customerData.surname ?? ''} onChange={handleChange}/>
            </div>
          </div>

          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">

            <div className="flex-1">
              <Label className="text-sm">Ulica</Label>
              <Input
                  className="mt-1.5"
                  defaultValue={customerData.street ?? ''}
                  name="street"
                  onChange={handleChange}
              />
            </div>
            <div className="sm:w-1/2">
              <Label className="text-sm">Číslo domu</Label>
              <Input className="mt-1.5" defaultValue={customerData.street_number ?? ''} name="street_number" onChange={handleChange}/>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Mesto</Label>
              <Input className="mt-1.5" defaultValue={customerData.city ?? ''} name="city" onChange={handleChange}/>
            </div>
            <div>
              <Label className="text-sm">PSČ</Label>
              <Input className="mt-1.5" defaultValue={customerData.postal_code ?? ''} name="postal_code" onChange={handleChange}/>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1  gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Štát</Label>
              <Input className="mt-1.5" defaultValue={customerData.state ?? 'Slovensko'} name="state" onChange={handleChange}/>
            </div>

          </div>


          <div>
            <Label className="text-sm">Vaše telefónne čislo</Label>
            <Input className="mt-1.5" defaultValue={customerData.tel ?? ''} type={"tel"} name="tel"
                   onChange={handleChange}/>
          </div>
          <div>
            <Label className="text-sm">Váš e-mail</Label>
            <Input className="mt-1.5" type={"email"} name="email" defaultValue={customerData.email ?? ''}
                   onChange={handleChange}/>
          </div>


          <div>
            <Checkbox
                className="!text-sm"
                name="deliverToAnotherAddress"
                label="Doručiť na inú adresu ako fakturačnú"
                defaultChecked={customerData.deliverToAnotherAddress ?? false}
                // @ts-ignore
                onChange={toggleDeliverToAnotherAddress}
            />
          </div>
          {
              customerData.deliverToAnotherAddress && <div className={"border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6"}>
                <h3 className="text-lg font-semibold">Adresa, na ktorú chcete doručiť objednávku</h3>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                  <div>
                    <Label className="text-sm">Meno</Label>
                    <Input className="mt-1.5" name="name2" defaultValue={customerData.name2 ?? ''} onChange={handleChange}/>
                  </div>
                  <div>
                    <Label className="text-sm">Priezvisko</Label>
                    <Input className="mt-1.5" name="surname2" defaultValue={customerData.surname2 ?? ''} onChange={handleChange}/>
                  </div>
                </div>

                {/* ============ */}
                <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">

                  <div className="flex-1">
                    <Label className="text-sm">Ulica</Label>
                    <Input
                        className="mt-1.5"
                        defaultValue={customerData.street2 ?? ''}
                        name="street2"
                        onChange={handleChange}
                    />
                  </div>
                  <div className="sm:w-1/2">
                    <Label className="text-sm">Číslo domu</Label>
                    <Input className="mt-1.5" defaultValue={customerData.street_number2 ?? ''} name="street_number2" onChange={handleChange}/>
                  </div>
                </div>

                {/* ============ */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                  <div>
                    <Label className="text-sm">Mesto</Label>
                    <Input className="mt-1.5" defaultValue={customerData.city2 ?? ''} name="city2" onChange={handleChange}/>
                  </div>
                  <div>
                    <Label className="text-sm">PSČ</Label>
                    <Input className="mt-1.5" defaultValue={customerData.postal_code2 ?? ''} name="postal_code2" onChange={handleChange}/>
                  </div>
                </div>

                {/* ============ */}
                <div className="grid grid-cols-1  gap-4 sm:gap-3">
                  <div>
                    <Label className="text-sm">Štát</Label>
                    <Input className="mt-1.5" defaultValue={customerData.state2 ?? 'Slovensko'} name="state2" onChange={handleChange}/>
                  </div>

                </div>


                <div>
                  <Label className="text-sm">Telefónne čislo</Label>
                  <Input className="mt-1.5" defaultValue={customerData.tel2 ?? ''} type={"tel"} name="tel2"
                         onChange={handleChange}/>
                </div>
                <div>
                  <Label className="text-sm">E-mail</Label>
                  <Input className="mt-1.5" type={"email"} name="email2" defaultValue={customerData.email2 ?? ''}
                         onChange={handleChange}/>
                </div>


              </div>
          }


          <div className="w-full">
            <Label className="text-sm">Poznámka k objednávke</Label>
            <textarea
                className="w-full bg-gray-100 border border-gray-300 rounded py-2 px-4 resize-none"
                rows={4}
                name="order_note"
                placeholder=""
                defaultValue={customerData.order_note ?? ''}
                // @ts-ignore
                onChange={handleChange}
            ></textarea>
          </div>
          <div>
            <Checkbox
                className="!text-sm"
                name="orderAsAGift"
                label="Chcem poslať balík ako darček, neprikladajte faktúru."
                defaultChecked={customerData.orderAsAGift ?? false}
                // @ts-ignore
                onChange={toggleOrderAsAGift}
            />
          </div>
          {!user &&
              <div>
                <Checkbox
                    className="!text-sm"
                    name="automaticRegistration"
                    label="Po odoslaní objednávky ma automaticky zaregistrujte v e-shope MojaVec"
                    defaultChecked={customerData.automaticRegistration ?? false}
                    // @ts-ignore
                    onChange={toggleAutomaticRegistration}
                />
              </div>

          }

          {/* ============ */}
          {/*
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
                className="sm:!px-7 shadow-none"
                onClick={() => onCloseActive()}
            >
              Uložiť a prejsť na doručovacie údajte
            </ButtonPrimary>
            <ButtonSecondary
                className="mt-3 sm:mt-0 sm:ml-3"
                onClick={() => onCloseActive()}
            >
              Neuložiť
            </ButtonSecondary>
          </div>
          */}
        </div>
      </div>
    );
  };

  return renderAccount();
};

export default ContactInfo;
