import React, {useEffect, useState} from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {useUserStore} from "./store/userStore";
import axios from "axios";

function App() {

 const { user, setUser, emptyUser } = useUserStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchUserStatus = async () => {
        if(!loading) {
            if (!user) {
                const jwt = localStorage.getItem('axjwt')
                if (jwt) {

                    const apiUrl = 'https://api.mojavec.com/v1/auth/'; // Replace with your API endpoint

                    try {
                        const response = await axios.get(apiUrl, {
                            headers: {
                                Authorization: `Bearer ${jwt}`,
                            },
                            params: {
                                a: 'is_logged_in',
                            },
                        });

                        setUser(response.data.user); // Assuming the API response contains a user object or null
                    } catch (err) {
                        // @ts-ignore
                        setError(err.message);
                    } finally {
                        setLoading(false);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if(!loading)fetchUserStatus();
        console.log('app useeffect')

    }, []);



  return (
    <HelmetProvider>
      <Helmet>
        <title>MOJA Vec | MOJA Vec</title>
        <meta
          name="E-shop MOJA Vec"
          content="MOJA Vec | MOJA Vec"
        />
      </Helmet>

      {/* MAIN APP */}
      <div className="text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200"   style={{
          backgroundImage: "url('/logo/bg1.jpg')",
          backgroundRepeat: "repeat", // Ensures it repeats on X and Y
          backgroundSize: "auto", // Keeps the original size of the image
      }}>
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
