import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet-async";
import { _getImgRd, _getPersonNameRd, _getTitleRd } from "contains/fakeData";
import Tag from "shared/Tag/Tag";

const VratenieTovaru = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Všeobecné obchodné podmienky"
          >
            Vrátenie tovaru
          </h1>


          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="##">
                    Fones Mimi
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-1.5 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
        <div
            id="single-entry-content"
            className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert"
        >
          <div className="outer-container text-center" id="article_text">

            <h1>Vrátenie tovaru</h1>
            <p>
              Pre vrátenie tovaru si vytlačte, vyplňte a priložte:<br /> <strong><a href="https://mojavec.com/web_files/nVG0E6v7r5peyplA8ydD84Z1jzlg9Q.pdf" target="_BLANK">Formulár pre vrátenie tovaru [PDF - 380kB]</a></strong>.<br/>

            </p>

          </div>

        </div>
    );
  };

  return (
      <div className="nc-PageSingle pt-8 lg:pt-16 ">
        <Helmet>
          <title>Vrátenie tovaru || MojaVec</title>
        </Helmet>


        <div className="nc-SingleContent container space-y-10 bg-white p-5">
          {renderContent()}
        </div>

      </div>
  );
};

export default VratenieTovaru;
